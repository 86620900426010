/** @jsxImportSource @emotion/core */
import * as React from "react";
import { jsx } from "@emotion/core";

import service, { useGetApi, usePostApi, useDeleteApi } from "../../../api/RBWebservice";
import * as Api from "../../../api/RBApi";
import * as RB from "../../../framework/RBFramework";
import * as Css from "./OrganisationEditor.module.css";
import * as WDateUtils from "../../../core/WDateUtils";
import { cloneDeep } from "lodash";
import { ModalWithButtons, Input, ToolbarPanel, Panel, Button } from "../../../framework/RBFramework";

interface IOrganisationEditorProps {
	id: number;
	addBranche: () => void;
}

export function OrganisationEditor({ id, addBranche }: IOrganisationEditorProps) {
	const [isLoading, setLoading] = React.useState(0);
	const [getResult, callGetApi, setResult] = useGetApi<Api.Organisation>(setLoading);
	const [postResult, callPostApi] = usePostApi<Api.Organisation, Api.Organisation>(setLoading);
	const [, callUnreserveBicycle] = useDeleteApi(setLoading);

	const [getEnableW2kImportResult, callGetEnableW2kImportApi] = useGetApi<boolean>(setLoading);
	const [postEnableW2kImportResult, callPostEnableW2kImportApi] = usePostApi(setLoading);

	const [showUnreserveBicycle, setShowUnreserveBicycle] = React.useState(false);
	const [unreserveBicycleId, setUnreserveBicycleId] = React.useState("");

	React.useEffect(() => {
		callGetApi({ url: `/api/v1/Organisations/${encodeURIComponent(id)}` });
	}, [callGetApi, id]);

	// Import status opnieuw ophalen na post call om w2k import te enablen of disablen.
	React.useEffect(() => {
		callGetEnableW2kImportApi({ url: `/api/v1/Organisations/${encodeURIComponent(id)}/w2kimport` });
	}, [postEnableW2kImportResult, callGetEnableW2kImportApi, id]);

	function changePropertyOfResult(property: keyof Api.Organisation, value: Api.Organisation[keyof Api.Organisation]) {
		const newObject = cloneDeep(getResult);
		if (newObject.result) {
			// Typescript geeft wat compilatie errors op volgende regel
			// Aangezien het wel werkt doen wij hier een ts-ignore
			//@ts-ignore
			newObject.result[property] = value;
		}
		setResult(newObject);
	}

	function save() {
		if (getResult && getResult.result) {
			callPostApi({ url: "/api/v1/Organisations", data: getResult.result });
		}
	}

	function reloadWithoutSaving() {
		callGetApi({ url: `/api/v1/Organisations/${encodeURIComponent(id)}` });
	}

	function enableW2KImport() {
		enableDisableW2KImport(true);
	}

	function disableW2KImport() {
		enableDisableW2KImport(false);
	}

	function enableDisableW2KImport(enable: boolean) {
		if (getResult && getResult.result) {
			callPostEnableW2kImportApi({ url: `/api/v1/Organisations/${encodeURIComponent(id)}/w2kimport/${enable}`, data: null });
		}
	}

	function unreserveBicycle() {
		setUnreserveBicycleId("");
		setShowUnreserveBicycle(false);

		if (getResult && getResult.result) {
			callUnreserveBicycle({ url: `/api/v1/StockBicycles/${getResult.result.id}/${encodeURIComponent(unreserveBicycleId)}/unreserve` });
		}
	}

	const repairReservedOrders = () => {
		void service.post(`/api/v1/Organisations/${encodeURIComponent(id)}/repair_reserved_orders`)
	}

	function renderOrganisation() {
		if (getResult && getResult.result && getEnableW2kImportResult && getEnableW2kImportResult.result !== undefined) {
			return (
				<div>
					{showUnreserveBicycle &&
						<ModalWithButtons header="Fiets losmaken" onCloseClicked={() => setShowUnreserveBicycle(false)} buttons={[
							{ children: "Losmaken", color: "green", onClick: unreserveBicycle },
							{ children: "Annuleren", color: "red", onClick: () => setShowUnreserveBicycle(false) }
						]}>
							<Input label="Wilmar ID fiets" value={unreserveBicycleId} onChange={setUnreserveBicycleId} autoFocus={true} />
						</ModalWithButtons>
					}
					<ToolbarPanel toolbar={[
						<RB.ToolbarButton key="save" caption="Opslaan" icon="save" onClick={save} />,
						<RB.ToolbarButton key="cancel" caption="Annuleren" icon="cancel" onClick={reloadWithoutSaving} />,
						<RB.ToolbarButton key="read" caption="W2K DB inlezen" icon="database" onClick={enableW2KImport} />,
						<RB.ToolbarButton key="losmaken" caption="Fiets losmaken" icon="bicycle" onClick={() => setShowUnreserveBicycle(true)} />,
						<RB.ToolbarButton key="New" caption="Nieuw filiaal" icon="add" onClick={addBranche} />
					]}>
						<Panel overflowY="auto">
							<h1>Organisatie {getResult.result.id}</h1>
							{getEnableW2kImportResult.result &&
								<RB.Message type="warning">
									<span>Er mag een Wilmar 2000 database worden ingelezen bij deze klant.</span>
									<br />
									<RB.LinkButton className="hyperLinkButton" onClick={disableW2KImport}>Klik hier om dat weer uit te schakelen.</RB.LinkButton>
								</RB.Message>
							}
							<RB.WindowBox caption="Basis gegevens" style={{ width: "100%" }}>
								<div className={Css.organisationContent}>
									<RB.Input label="Naam" value={getResult.result.name} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("name", value)} />
									<br />
									<RB.Input label="Unieke code" value={getResult.result.uniqueCode} readOnly={true} style={{ width: "100%" }} />
									<br />
									<span className={Css.labelContent}>{"Hoofdkantoor"}</span>
									<RB.Checkbox value={getResult.result.isHQ} style={{ width: "100%" }} onChange={(value) => changePropertyOfResult("isHQ", value)} />
									<br />
									<RB.Dropdown label="Styling" value={getResult.result.styling} style={{ width: "100%" }} options={[
										{ key: Api.Styling.Wilmar, value: Api.Styling.Wilmar, text: "Wilmar" },
										{ key: Api.Styling.Giant, value: Api.Styling.Giant, text: "Giant" },
										{ key: Api.Styling.EbikeStore, value: Api.Styling.EbikeStore, text: "E-Bike Store" }
									]} onChange={(value) => changePropertyOfResult("styling", value)} />
									<br />
									<b>Programma gebruik</b>
									<div className={Css.gridRow}>
										<RB.Dropdown value={getResult.result.loginMode} width="auto" onChange={(value) => changePropertyOfResult("loginMode", value)} options={[
											{ key: Api.LoginMode.Enabled, value: Api.LoginMode.Enabled, text: "Ja" },
											{ key: Api.LoginMode.Disabled, value: Api.LoginMode.Disabled, text: "Nee" },
											{ key: Api.LoginMode.Date, value: Api.LoginMode.Date, text: "Datum limiet" }]} />
										{getResult.result.loginMode === Api.LoginMode.Date &&
											<div className={Css.gridCell}>
												<span>&nbsp;t/m&nbsp;</span>
												<RB.DatePicker value={getResult.result.dateLimit || WDateUtils.now()} onChanged={(d) => changePropertyOfResult("dateLimit", d)} />
											</div>
										}
										{getResult.result.loginMode !== Api.LoginMode.Disabled &&
											<div className={Css.gridCell}>
												<span>&nbsp;met editie&nbsp;</span>
												<RB.Dropdown value={getResult.result.edition} width="auto" onChange={(value) => changePropertyOfResult("edition", value)} options={[
													{ key: Api.Edition.EntryEdition, value: Api.Edition.EntryEdition, text: "Starter Edition" },
													{ key: Api.Edition.CenterEdition, value: Api.Edition.CenterEdition, text: "Extended Edition" },
													{ key: Api.Edition.FullEdition, value: Api.Edition.FullEdition, text: "Full Edition" }]} />
												<span>&nbsp;en&nbsp;</span>
												<RB.Dropdown value={getResult.result.enableCashRegister} width="auto" onChange={(value) => changePropertyOfResult("enableCashRegister", value)} options={[
													{ key: true, value: true, text: "met kassa" },
													{ key: false, value: false, text: "zonder kassa" }]} />
											</div>
										}
										<RB.Dropdown
											label="Valuta"
											value={getResult.result.currency}
											style={{ width: "100%" }} 
											options={[
												{ key: Api.CurrencySetting.EUR, value: Api.CurrencySetting.EUR, text: "Euro" },
												{ key: Api.CurrencySetting.DKK, value: Api.CurrencySetting.DKK, text: "Deense kronen" },
												{ key: Api.CurrencySetting.PLN, value: Api.CurrencySetting.PLN, text: "złoty" }
											]}
											onChange={(value) => changePropertyOfResult("currency", value)} 
										/>
									</div>
								</div>
							</RB.WindowBox>
							<br /><br />
							<RB.WindowBox caption="Repareren" style={{ width: "100%" }}>
								<Button onClick={repairReservedOrders}>Gereserveerd in orders</Button>
							</RB.WindowBox>
							<br /><br />
							<RB.WindowBox caption="Opmerkingen" style={{ width: "100%" }}>
								<i>Deze opmerkingen zijn niet zichtbaar voor de klant.</i>
								<RB.TextArea value={getResult.result.remarks} onChange={(value) => changePropertyOfResult("remarks", value)} style={{ width: "100%", minHeight: 200 }} />
							</RB.WindowBox>
						</Panel>
					</ToolbarPanel>
				</div>
			);
		}

		return null;
	}

	return (
		<RB.Loader isLoading={isLoading > 0}>
			<RB.Panel>
				{getResult && getResult.error && <RB.Message type="error">{getResult.error.message}</RB.Message>}
				{postResult && postResult.error && <RB.Message type="error">{postResult.error.message}</RB.Message>}
				{postEnableW2kImportResult && postEnableW2kImportResult.error && <RB.Message type="error">{postEnableW2kImportResult.error.message}</RB.Message>}

				{renderOrganisation()}
			</RB.Panel>
		</RB.Loader>
	);
}